<template>
    <div :class="isReport ? 'divi' : 'resultBox'">
        <!--좌 이미지-->
        <ul class="imgBox handsimg" v-if="!isFirstRecord">
            <li>
                <div class="imgW taC"><img :src="record.firstHands.handsImgUrl" alt="최초 분석 수부 엑스레이 이미지" @error="onErrorImage($event)"></div>
                <p class="date">{{ record.firstHands.pictureDtStr }} (최초 분석)</p>
            </li>
            <li>
                <div class="imgW taC"><img :src="record.current.handsImgUrl" alt="현재 수부 엑스레이 이미지" @error="onErrorImage($event)"></div>
                <p class="date current">{{ record.current.pictureDtStr }} (현재)</p>
            </li>
        </ul>
        <div class="imgBox firstBox" v-else>
            <div class="imgW taC"><img :src="record.current.handsImgUrl" alt="현재 수부 엑스레이 이미지" @error="onErrorImage($event)"></div>
            <p class="date current">{{ record.current.pictureDtStr }} (현재)</p>
        </div>
        <!--//좌 이미지-->
        <!--우 텍스트-->
        <ul  :class="[`txtList`, isFirstRecord ? `firstTextList` : ``]">
            <li>
                <div class="em">
                    <p class="leftTxt">{{ READING_HANDS_NAME }}</p>
                    <p class="rightTxt">{{ record.current.boneAgeStr }}</p>
                </div>
            </li>
            <li>
                <p class="tit">실제 연령과의 차이</p>
                <ul class="resultInfo">
                    <li>
                        <p class="leftTxt">실제 연령</p>
                        <p class="rightTxt">{{ record.current.atTimeAgeStr }}</p>
                    </li>
                    <li>
                        <p class="leftTxt">연령 차이</p>
                        <p class="rightTxt">{{ record.current.ageDiff }}</p>
                    </li>
                </ul>
            </li>
        </ul>
        <!--//우 텍스트-->
    </div>
</template>

<script>
export default {
    components: {
    },
    props: {
        record: {
            type: Object,
            default: () => ({})
        },
        isFirstRecord: {
            default: true
        },
        isReport: {
            default: false
        }
    },
    data() {
        return {
        };
    },
    watch: {},
    created() {},
    mounted() {
    },
    destroyed() {},
    methods: {
    },
    computed:{},
};
</script>

<style lang="scss" scoped>
@import "@/sass/app.scss";
#report .box > li .divi div.imgBox.firstBox{display:flex; flex-direction:column; justify-content:center; align-items:center; gap:8px; width:200px;}
#report .box > li .divi div.imgBox.firstBox .date.current{font-size:1.2rem; color:var(--color-point);}
#report .box > li .divi div.imgBox.firstBox .imgW{width:100%; height:180px; background:#444; position: relative;}
#report .box > li .divi div.imgBox.firstBox .imgW img{position: absolute; max-width: 100%; max-height: 100%; left: 50%; top: 50%; transform: translate(-50%, -50%);}
#report .box > li .divi .txtList{width:calc(100% - 216px);}
</style>
